import "../legal.css";

import * as React from "react";
import { useTranslation } from "react-i18next";
// import { Trans } from "react-i18next"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import { Parallax } from "react-scroll-parallax";

import Layout from "../../../components/layout/Layout";
import Seo from "../../../components/seo";
import SectionLanding from "../../../components/section/SectionLanding";
import Mask3 from "../../../svg/masks/mask3.svg";
// import { Link } from "gatsby-plugin-react-i18next";

export default function IndexPage() {
	const { t } = useTranslation();
	return (
		<Layout>
			<Seo title={t("Aviso legal")} />

			<StaticImage
				src={`../../../images/contacto.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>
			{/* <Parallax speed={-10} className="parallaxImg">
				<StaticImage
					src={`../../../images/contacto.jpg`}
					className="landing-bg"
					alt="Section background"
					layout="fullWidth"
				/>
			</Parallax> */}
			<Mask3 />

			<SectionLanding title={t("Canal ético")} />

			<div className="legalBody section invertColors" id="avisoLegal">
				<p>
					BEGIN RESTAURANTES S.L., tiene el firme compromiso de ofrecer el mejor
					servicio a sus clientes, el mejor trato a sus proveedores y el mejor
					entorno laboral para las personas trabajadoras. En definitiva, que
					nuestro comportamiento sirva para construir una sociedad mejor para
					todos.
				</p>
				<p>
					BEGIN RESTAURANTES S.L., pone a su disposición este lugar donde poner
					en nuestro conocimiento cualquier infracción penal o administrativa,
					conductas irregulares, incumplimientos de nuestro código ético o
					régimen interno, actos ilegales (cualquier delito como fraude, robos,
					corrupción, malversación, etc.), y cualquier otro hecho que afecte de
					forma negativa directa o indirectamente a nuestra entidad.
				</p>
				<p>
					Nuestro Canal garantiza la confidencialidad, tanto de la información
					comunicada, los datos personales de la persona que informe, así como
					los de las personas denunciadas o afectadas, cumpliendo con la Ley
					2/2023, de 20 de febrero, de Protección al Informante, el Reglamento
					(UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de
					2016, así como la Ley Orgánica 3/2018, de 5 de diciembre.
				</p>
				<p>
					El cumplimiento de todo lo expuesto y de la gestión de recepción de
					denuncias remitidas desde nuestro Canal Interno de Denuncias, está
					externalizado en la consultora independiente, Business Adapter®.
				</p>
				<p>
					Antes de denunciar, recuerde que debe tener motivos razonables para
					creer, según la información y circunstancias que disponga en el
					momento de la denuncia, que los hechos denunciados son ciertos.
					Nuestra entidad analizará la verisimilitud de su denuncia y podrá
					inadmitirla si incumple con lo establecido en la Ley 2/2023 de 20 de
					febrero.
				</p>
				<p>
					<a
						href="https://www.businessadapter.es/canal-interno-de-denuncias-de-begin-restaurantes"
						style={{ textDecoration: "underline" }}
					>
						Canal de Denuncias
					</a>
					<br />
					<a
						href="/legal/codigoEtico.pdf"
						style={{ textDecoration: "underline" }}
					>
						Código Ético
					</a>
				</p>
				<br />
				<br />
				<br />
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
